@tailwind base;
@tailwind components;
@tailwind utilities;


*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
body {
  font-family: 'Poppins', sans-serif;
}